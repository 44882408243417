import clsx from 'clsx';

import styles from './ProgressBar.module.scss';
import { ProgressBarProps } from './ProgressBar.types';

export const ProgressBar = ({ progress, children, className }: ProgressBarProps) => {
  return (
    <div className={clsx(styles['progress-bar'], className)}>
      <div className={styles['progress-bar__progress']}>
        <div
          className={styles['progress-bar__progress-inner']}
          style={{ width: `${progress}%` }}
          data-testid="progress-bar"
        />
      </div>
      <div className={styles['progress-bar__labels']}>{children}</div>
    </div>
  );
};
