import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import Image from 'next/future/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useMedia } from 'react-use';

import { api } from '@endaoment-frontend/api';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Card } from '@endaoment-frontend/ui/shared';
import { getDate, getFormattedMoney, getFormattedNumber } from '@endaoment-frontend/utils';

import styles from './Hero.module.scss';
import { Circle, Globe, LeftCaret, RightCaret } from './HeroSVGs';
import { useHeroSlides } from './useHeroSlides';

const SLIDE_TIME = 1500; // Time to increment slide (in 1/10seconds)
const FADE_DURATION = 1.5; // Time for fade animation (in seconds)

export const Hero = () => {
  const { currentSlide, slideIndex, incrementSlide, decrementSlide, setSlide, slideCount, percentToNextSlide } =
    useHeroSlides(SLIDE_TIME);

  const { data: totalDonated } = useQuery(api.GetTotalDonations.key, api.GetTotalDonations.query);
  const { data: totalGranted } = useQuery(api.GetTotalGrants.key, api.GetTotalGrants.query);
  // TODO: Use a less data intensive endpoint (needs to be created?)
  const { data: deployedOrgs } = useQuery(api.GetDeployedOrgs.key, api.GetDeployedOrgs.query);

  const deployedOrgsCount = deployedOrgs?.length ?? 0;
  const percentDistributed = Math.round((totalGranted / totalDonated) * 100);

  const isMobile = useMedia('(max-width: 40rem)', true);
  const [statsOpen, setStatsOpen] = useState(false);

  useEffect(() => {
    if (!isMobile) setStatsOpen(!currentSlide.image);
  }, [currentSlide, isMobile]);

  const slideCircles = [];
  for (let i = 0; i < slideCount; i++) {
    slideCircles.push(
      <Circle
        key={i}
        height={11}
        onClick={() => setSlide(i)}
        className={clsx(styles['carousel-item-circle'], slideIndex !== i && styles['disabled'])}
      />,
    );
  }

  return (
    <section className={styles['hero']}>
      <m.div transition={{ duration: FADE_DURATION / 2 }} layout className={styles['text-section']}>
        <AnimatePresence exitBeforeEnter presenceAffectsLayout initial={false}>
          <m.h1
            key={currentSlide.title}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ ease: 'easeOut', duration: FADE_DURATION }}
          >
            {currentSlide.title}
          </m.h1>
        </AnimatePresence>
        <div className={styles['progress-bar']}>
          <div style={{ width: `${percentToNextSlide}%` }} />
        </div>
        <AnimatePresence exitBeforeEnter presenceAffectsLayout initial={false}>
          <m.div
            key={currentSlide.text}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: FADE_DURATION }}
            className={styles['slide-description']}
          >
            <div className={clsx(currentSlide.href && styles['has-arrow'], currentSlide.date && styles['has-date'])}>
              {currentSlide.href ? (
                <Link href={currentSlide.href ?? '#'}>
                  <a>
                    <p>{currentSlide.text}</p>
                    <ArrowIcon height={14} width={24} className={styles['link-arrow']} />
                  </a>
                </Link>
              ) : (
                <p>{currentSlide.text}</p>
              )}
              {currentSlide.date && <p className={styles['slide-date']}>{getDate(currentSlide.date)}</p>}
            </div>
          </m.div>
        </AnimatePresence>
        <div>
          <LeftCaret
            onClick={decrementSlide}
            className={clsx(styles['decrement-carousel'], slideIndex === 0 && styles['disabled'])}
          />
          {slideCircles}
          <RightCaret
            onClick={incrementSlide}
            className={clsx(styles['increment-carousel'], slideIndex === slideCount - 1 && styles['disabled'])}
          />
        </div>
      </m.div>

      <Card
        onMouseEnter={() => {
          if (currentSlide.image) {
            setStatsOpen(true);
          }
        }}
        onMouseLeave={() => {
          if (currentSlide.image) {
            setStatsOpen(false);
          }
        }}
        className={clsx(styles['stats-section'], styles['stats-open'])}
      >
        <div className={styles['stats-top']}>
          <div>
            <h3>{getFormattedMoney(totalDonated)}</h3>
            <span>Total Impact</span>
          </div>
          <div className={styles['stats-top-right']}>
            <h3>{getFormattedNumber(deployedOrgsCount)}</h3>
            <span>Total Orgs Deployed</span>
          </div>
        </div>
        <AnimatePresence initial={false}>
          {statsOpen && (
            <m.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{
                duration: FADE_DURATION / 2,
                ease: 'easeIn',
                when: 'beforeChildren',
                staggerChildren: 100000,
              }}
            >
              <m.hr
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: FADE_DURATION / 2, ease: 'linear' }}
              />
              <m.div
                className={styles['stats-bottom']}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: FADE_DURATION / 2, ease: 'linear' }}
              >
                <span>Ecosystem Breakdown</span>
                <div className={styles['rounded-progress-bar']}>
                  <div style={{ width: `${percentDistributed}%` }} />
                </div>
                <div>
                  <p>
                    <mark>{percentDistributed}%</mark> Distributed
                  </p>
                  <p>
                    <mark>{getFormattedMoney(totalDonated - totalGranted)}</mark> Grantable
                  </p>
                </div>
              </m.div>
            </m.div>
          )}
        </AnimatePresence>
      </Card>

      <AnimatePresence exitBeforeEnter presenceAffectsLayout initial={false}>
        <m.div
          key={slideIndex}
          className={clsx(styles['image-section'], !currentSlide.image && styles['overlay'])}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ ease: 'easeOut', duration: FADE_DURATION }}
        >
          {currentSlide.image ? (
            <div className={styles['image-section__container']}>
              <Image
                src={currentSlide.image}
                className={styles['image-section__image']}
                alt={currentSlide.title}
                height={876}
                width={1400}
                placeholder="blur"
                priority
              />
            </div>
          ) : (
            <Globe />
          )}
        </m.div>
      </AnimatePresence>
    </section>
  );
};
