import { ethers } from 'ethers';

import { config } from '@endaoment-frontend/config';
import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { Address, Fund, Org, NTEECode, Digit, Token, DonationDestination } from '@endaoment-frontend/types';

const ENDAOMENT_ACCOUNTS = [
  config.endaoment.accounts.accountant,
  config.endaoment.accounts.admin,
  config.endaoment.accounts.reviewer,
  config.endaoment.accounts.pauser,
].filter(Boolean);

export const isTokenETH = (token: Token) => !token.address && token.symbol === 'ETH';

export const equalAddress = (addr1?: Address | null, addr2?: Address | null): boolean => {
  if (!addr1 || !addr2) return false;

  return addr1.toLowerCase() === addr2.toLowerCase();
};

export const isAdministrativeAccount = (address?: Address | null) =>
  !!address && ENDAOMENT_ACCOUNTS.some((acc) => equalAddress(acc, address));

export const getFundTotalValues = (
  f: Fund | Fund[],
): {
  totalImpact: number;
  grantable: number;
  completed: number;
  locked: number;
} => {
  let totalImpact = 0;
  let grantable = 0;
  let completed = 0;
  let locked = 0;

  const funds = Array.isArray(f) ? f : [f];

  // @TODO: fix string numbers from API
  funds.forEach((fund) => {
    const parsedLifetime = +ethers.utils.formatUnits(fund.lifetimeDonations || 0, STABLECOIN_DECIMALS);
    totalImpact += +parsedLifetime;
    grantable += +fund.grantableBaseToken;
    completed += +parsedLifetime - +fund.fundBaseToken;
    locked += +fund.fundBaseToken - +fund.grantableBaseToken;
  });

  // @TODO: calculation leads to negative or small values due to lifetime being BN while others values are normal - fix this API side
  return {
    totalImpact: totalImpact <= 0.05 ? 0 : totalImpact,
    grantable: grantable <= 0.05 ? 0 : grantable,
    completed: completed <= 0.05 ? 0 : completed,
    locked: locked <= 0.05 ? 0 : locked,
  };
};

export const getNTEEMajorCode = (org: Org): NTEECode => {
  try {
    return org.nteeCode.trim()[0].toUpperCase() as NTEECode;
  } catch {
    return 'Z';
  }
};

export const getNTEECode = (org: Org): `${NTEECode}${Digit}${Digit}` => {
  const major = getNTEEMajorCode(org);

  // If unknown, return 'Z00'
  if (major === 'Z') return 'Z00';

  const minor = org.nteeCode.trim().slice(1, 3) as `${Digit}${Digit}`;

  return `${major}${minor}`;
};

export const getNTEECategory = (org: Org): string => {
  const major = getNTEEMajorCode(org);

  // If unknown, return 'Z00'
  if (major === 'Z') return 'Unknown Type';

  const category = org.nteeCode.trim().slice(5);

  return category;
};

export const getParsedUrl = (url?: string): string | null => {
  if (!url) return null;

  let parsedUrl: URL;
  try {
    parsedUrl = new URL(url);

    return parsedUrl.href.replace(/\/$/, '');
  } catch {
    return `http://${url}`;
  }
};

/**
 * Returns array of string values from obj
 * @param obj to select string values from
 * @returns an array of values from obj that for all the keys in ```T``` that are of type ```string```
 */
export const selectStringsFromObject = <T>(obj: T): string[] => {
  return obj === null || typeof obj === 'undefined'
    ? []
    : Object.values(obj).map((v) => (typeof v === 'string' ? v : ''));
};

/**
 * Returns an array of values from obj that the keys for are of type ```string```
 * @param array the origin array to filter
 * @param filterValue the string to use as a filter
 * @param [stringValueArrayForFilter] an optional index-mapped array of strings to filter array by
 * @returns the filtered array
 */
export const genericStringFilter = <T>(
  array: T[],
  filterValue: string,
  stringValueArrayForFilter?: string[][],
): T[] => {
  const fArray = stringValueArrayForFilter ?? array.map((item) => selectStringsFromObject(item));

  return array.filter((_t, index) =>
    fArray[index].reduce<boolean>(
      (prev, current) => prev || current?.toLowerCase().includes(filterValue.toLowerCase()),
      false,
    ),
  );
};

export const stringToColor = (stringInput: string): string => {
  const stringUniqueHash = stringInput.split('').reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export const mapFundToDestination = (fund: Fund): DonationDestination => ({
  id: fund.id,
  name: fund.name,
  type: 'fund',
  minContribution: fund.minContribution,
  routeId: fund.id,
  paypalId: fund.paypalId,
});

export const mapOrgToDestination = (org: Org): DonationDestination => ({
  id: org.id,
  name: org.name,
  type: 'org',
  minContribution: org.minContribution,
  routeId: org.ein,
});
