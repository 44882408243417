import { useState } from 'react';
import { useInterval } from 'react-use';

import { HERO_SLIDES } from '../../../editable';

export const useHeroSlides = (slideTime?: number) => {
  const [currSlide, setCurrSlide] = useState(0); // Current slide index
  const slideCount = HERO_SLIDES.length;

  const incrementSlide = () => {
    setCurrSlide((c) => (c < slideCount - 1 ? c + 1 : 0));
    setCountdown(0);
  };

  const decrementSlide = () => {
    setCurrSlide((c) => (currSlide > 0 ? c - 1 : slideCount - 1));
    setCountdown(0);
  };

  const [countdown, setCountdown] = useState(0); // Countdown to next slide
  useInterval(() => {
    if (slideTime) {
      // Increment slide every x seconds
      if (countdown > slideTime) {
        incrementSlide();
      } else {
        setCountdown((c) => c + 1);
      }
    }
  }, 10); // Occurs once per 1/10 second

  return {
    currentSlide: HERO_SLIDES[currSlide],
    slideIndex: currSlide,
    incrementSlide,
    decrementSlide,
    setSlide: setCurrSlide,
    slideCount,
    percentToNextSlide: (countdown / slideTime) * 100,
  };
};
