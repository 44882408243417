import { useQuery } from 'react-query';

import { api } from '@endaoment-frontend/api';

import { CommunityFundCard } from './CommunityFundCard';
import { CommunityFundCardProps } from './CommunityFundCard.types';

export const SMCommunityFundCard = ({ fund, ...props }: Omit<CommunityFundCardProps, 'grantsCount'>) => {
  const grantsResult = useQuery([api.GetFundGrants.key, fund.id], () => api.GetFundGrants.execute(fund.id || ''));
  const grantsCount = grantsResult.data?.data.length;

  return <CommunityFundCard fund={fund} grantsCount={grantsCount} {...props} />;
};
