import { useQuery } from 'react-query';
import { useMedia } from 'react-use';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { api } from '@endaoment-frontend/api';
import { config } from '@endaoment-frontend/config';
import { Button } from '@endaoment-frontend/ui/shared';
import { SMCommunityFundCard, SMOrgCard } from '@endaoment-frontend/ui/smart';

import 'swiper/css';
import styles from './ExploreEcosystemSection.module.scss';

export const ExploreEcosystemSection = () => {
  const { data: featuredFunds } = useQuery(api.GetFeaturedFunds.key, api.GetFeaturedFunds.query);
  const { data: featuredOrgs } = useQuery(api.GetFeaturedOrgs.key, api.GetFeaturedOrgs.query);

  const funds = featuredFunds ?? [];
  const orgs = featuredOrgs ?? [];

  const isMobile = useMedia('(max-width: 71rem)', true);

  return (
    <section className={styles['explore-ecosystem-section-container']}>
      <section className={styles['explore-ecosystem-section']}>
        <h3>Explore the Ecosystem</h3>
        <hr />
        <span>Featured Organizations</span>

        <Swiper
          direction="horizontal"
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={25}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          speed={1000}
          className={styles['ecosystem-org-carousel']}
        >
          {orgs.map((org) => (
            <SwiperSlide key={org.ein} className={styles['ecosystem-slide']}>
              <SMOrgCard org={org} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          variation="org"
          as="a"
          href={`${config.clientBaseUrl}/orgs`}
          target="_blank"
          rel="noreferrer"
          className={styles['more-button']}
          filled
        >
          See More
        </Button>
        <hr />
        <span>Featured Funds</span>
        <Swiper
          direction="horizontal"
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={25}
          loop
          modules={[Autoplay]}
          speed={1000}
          autoplay={{ delay: 7500, disableOnInteraction: false }}
          className={styles['ecosystem-fund-carousel']}
        >
          {funds.map((fund) => (
            <SwiperSlide key={fund.id} className={styles['ecosystem-slide']}>
              <SMCommunityFundCard fund={fund} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Button
          variation="fund"
          as="a"
          href={`${config.clientBaseUrl}/funds`}
          target="_blank"
          rel="noreferrer"
          className={styles['more-button']}
          filled
        >
          See More
        </Button>
      </section>
    </section>
  );
};
