import clsx from 'clsx';
import Link from 'next/link';

import { config } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes/app';
import { StarIcon } from '@endaoment-frontend/ui/icons';
import { Avatar, Card, ProgressBar } from '@endaoment-frontend/ui/shared';
import { getFormattedMoney, getFundTotalValues, parseHtml, stringToColor } from '@endaoment-frontend/utils';

import styles from './CommunityFundCard.module.scss';
import { CommunityFundCardProps } from './CommunityFundCard.types';

export const CommunityFundCard = ({ fund, grantsCount = 0, className }: CommunityFundCardProps) => {
  const totalValues = getFundTotalValues(fund);
  const percentDistributed = (1 - totalValues.grantable / totalValues.totalImpact) * 100;

  return (
    <Link href={config.clientBaseUrl + routes.vanityUrlFund({ fundVanityUrl: fund.vanityUrl ?? '' })}>
      <a className={clsx(styles['community-fund-card'], className)}>
        <Card>
          <header>
            {fund.address && (
              <>
                <Avatar className={styles['avatar']}>
                  {fund.icon ? (
                    <img src={fund.icon} alt={`${fund.name} logo`} loading="lazy" />
                  ) : (
                    <StarIcon
                      startColor={stringToColor(fund.name)}
                      endColor={stringToColor(fund.name.split('').reverse().join(''))}
                    />
                  )}
                </Avatar>
                <div className={styles['total']}>
                  <h3 className={styles['total-amount']}>{getFormattedMoney(totalValues.totalImpact)}</h3>
                  <p className={styles['total-label']}>Total Impact</p>
                </div>
              </>
            )}
          </header>
          <div className={styles['content']}>
            <div className={styles['content-header']}>
              <h3 className={styles['content-name']}>{fund.name}</h3>
            </div>
            <div className={styles['content-description']}>{parseHtml(fund.shortDescription || '')}</div>
          </div>
          {fund.lifetimeDonations > 0 && (
            <ProgressBar progress={percentDistributed} className={styles['progress']}>
              <span>
                <em>{Math.round(percentDistributed)}%</em> Distributed
              </span>
              {grantsCount > 0 && (
                <span>
                  <em>
                    {grantsCount} Grant{grantsCount > 1 && 's'}
                  </em>
                </span>
              )}
            </ProgressBar>
          )}
          <div />
          <div className={styles['footer']}>
            <span>
              {'Advised by '}
              <em>
                {fund.primaryAdvisorFirstName} {fund.primaryAdvisorLastName}
              </em>
            </span>
            <em>Community Fund</em>
          </div>
        </Card>
      </a>
    </Link>
  );
};
