import type { GetStaticProps, NextPage } from 'next';

import { api, makeDehydratedQueries } from '@endaoment-frontend/api';
import { config } from '@endaoment-frontend/config';
import { StarIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import { ForNonProfitsSection, Hero, EndaomentInterfaceSection, ExploreEcosystemSection } from '../content/home';
import { ImageButtonRow } from '../content/imageButtonRow';
import { MediaCarousel } from '../content/mediaCarousel';
import { MetaTags } from '../content/metaTags';
// import { NDAOTokenCallout } from '../content/ndaoTokenCallout';
import { NDAOTokenCallout } from '../content/ndaoTokenCallout';
import { SocialLinks } from '../content/socialLinks';
import { TextGridWithLogo } from '../content/textGridWithLogo';
import styles from '../styles/index.module.scss';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      dehydratedState: await makeDehydratedQueries(
        [api.GetTotalDonations.key, api.GetTotalDonations.query],
        [api.GetTotalGrants.key, api.GetTotalGrants.query],
        [api.GetDeployedOrgs.key, api.GetDeployedOrgs.query],
        [api.GetFeaturedFunds.key, api.GetFeaturedFunds.query],
        [api.GetFeaturedOrgs.key, api.GetFeaturedOrgs.query],
      ),
    },
    // ISR every 1 minute
    revalidate: 60,
  };
};

const Home: NextPage = () => {
  return (
    <>
      <MetaTags url="/" />

      <Hero />
      <ImageButtonRow className={styles['three-image-buttons']}>
        {/* <div className={styles['three-image-buttons__inner']}> */}
        <ImageButtonRow.Button
          href={config.clientBaseUrl}
          label="Donate Directly"
          paragraph="Choose from over 1.5 million nonprofits."
          cardIcon={<StarIcon />}
        />
        <ImageButtonRow.Button
          href={`${config.clientBaseUrl}/funds/create`}
          label="Start a Fund"
          paragraph="Give now, get a receipt and grant over time."
          cardIcon={<StarIcon />}
        />
        <hr />
        <ImageButtonRow.Button
          href={`${config.clientBaseUrl}/orgs`}
          label="Claim your Nonprofit"
          paragraph="Accept crypto at no cost with no prior knowledge necessary."
          cardIcon={<StarIcon />}
        />
        {/* </div> */}
      </ImageButtonRow>

      <ForNonProfitsSection />
      <NDAOTokenCallout />

      <section className={styles['text-grid-container']}>
        <TextGridWithLogo
          headerText="The first tax-exempt community foundation providing philanthropic financial services on-chain."
          pillText="For Donors, For Everyone"
        >
          <TextGridWithLogo.Item
            header="Donor-Advised Funds"
            text="Donate any asset at any time and get a tax receipt immediately. Come back later and make grant recommendations to more than 1.5 million nonprofits whenever inspiration strikes."
            buttonText="Open a Fund"
            href={`${config.clientBaseUrl}/funds/create`}
          />
          <TextGridWithLogo.Item
            header="Direct Donations"
            text="Know where you want to give? Head straight to the profile of your favorite nonprofit and easily donate any crypto asset directly to the organization."
            buttonText="Find an Org"
            href={`${config.clientBaseUrl}/orgs`}
          />
          <TextGridWithLogo.Item
            header="Community Funds"
            text="Not sure where to give? Community Funds leverage our incredible community's knowledge base to direct donations around specific issue areas and causes."
            buttonText="Browse Community Funds"
            href={`${config.clientBaseUrl}/funds/community`}
          />
          <TextGridWithLogo.Item
            header="Endorsements"
            text="Soon you can help us curate the best and most effective nonprofits by endorsing them. Earn rewards for contributing your perspective while driving excess fee revenue to organizations you endorse."
            comingSoon
            // TODO: Figure out where this goes
            href={'#'}
          />
          <TextGridWithLogo.Item
            header="Portfolio Allocations"
            text="Don't let your philanthropic capital sit idly by while you find the perfect organization to support. Easily deploy dollars in your Endaoment Fund into DeFi yield bearing and crypto investment options."
            comingSoon
            // TODO: Figure out where this goes
            href={'#'}
          />
        </TextGridWithLogo>
      </section>

      <EndaomentInterfaceSection />
      <ExploreEcosystemSection />
      <MediaCarousel />
      <SocialLinks />
    </>
  );
};

export default Home;
