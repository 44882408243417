import { StarCircleIcon, SupportIcon, EthereumIcon, ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';

import { SMEINLookup } from '../../../smart';

import styles from './ForNonProfitsSection.module.scss';

export const ForNonProfitsSection = () => {
  return (
    <section className={styles['for-non-profits-section-container']}>
      <div className={styles['for-non-profits-section']}>
        <div>
          <Pill size="large" variation="blue">
            For Nonprofits
          </Pill>
          <div>
            <h3>
              Your nonprofit now
              <br />
              accepts any cryptocurrency,
              <br />
              delivered as dollars.*
            </h3>
            <Button as="a" href="https://docs.endaoment.org" target="_blank" rel="noreferrer">
              Learn More
              <ArrowIcon color="#53ACDE" width={20} height={12} />
            </Button>
          </div>
        </div>
        <div>
          <h4>Additional features</h4>
          <hr />
          <div>
            <EthereumIcon />
            <div>Hassle-free, zero-cost crypto fundraising for nonprofits</div>
          </div>
          <div>
            <SupportIcon />
            <div>Comprehensive support helping you to reach your goals</div>
          </div>
          <div>
            <StarCircleIcon />
            <div className={styles['coming-soon']}>Passive funding through endorsement rewards</div>
          </div>
        </div>
      </div>
      <SMEINLookup />
      <p className={styles['footnote']}>
        *Nonprofits must be in good standing with the IRS and compliant with&nbsp;
        <a href="https://docs.endaoment.org/governance/mission-values" target="_blank" rel="noreferrer">
          Endaoment’s Organization Funding Policy
        </a>
      </p>
    </section>
  );
};
