import { config } from '@endaoment-frontend/config';

export type OrgsRouteParams = { search?: string; page?: string };
export type OrgRouteParams = { ein: string; view?: 'contributions' };
export type OrgClaimRouteParams = { ein: string };
export type FundRouteParams = { id: string; view?: 'grants' | 'donations' };
export type VanityUrlFundRouteParams = { fundVanityUrl: string; view?: FundRouteParams['view'] };
export type ExtTransactionRouteParams = { hash: string };
export type ExtAddressRouteParams = { address: string };

export const routes = {
  home: () => '/',
  orgs: () => '/orgs',
  org: ({ ein }: OrgRouteParams) => `/orgs/${ein}`,
  orgClaim: ({ ein }: OrgClaimRouteParams) => `/orgs/${ein}/claim`,
  myFunds: () => '/funds',
  communityFunds: () => '/funds/community',
  vanityUrlFund: ({ fundVanityUrl, view }: VanityUrlFundRouteParams) =>
    `/${fundVanityUrl}${view ? `?view=${view}` : ''}`,
  createFund: () => '/funds/create',
  fund: ({ id, view }: FundRouteParams) => `/funds/${id}${view ? `?view=${view}` : ''}`,
  // External
  extViewTransaction: ({ hash }: ExtTransactionRouteParams) => `${config.explorerUrlPrefix.transaction}/${hash}`,
  extViewAddress: ({ address }: ExtAddressRouteParams) => `${config.explorerUrlPrefix.address}/${address}`,
  ndaoAdminDashboard: () => '/ndao/admin',
};
