import { useQuery } from 'react-query';

import { api } from '@endaoment-frontend/api';

import { OrgCard } from './OrgCard';
import { OrgCardProps } from './OrgCard.types';

export const SMOrgCard = ({ org, showPills, ...props }: Omit<OrgCardProps, 'grantCount' | 'donationCount'>) => {
  const grantsResult = useQuery([api.GetOrgGrants.key, org.ein], () => api.GetOrgGrants.execute(org.id), {
    enabled: showPills,
  });
  const donationsResult = useQuery(
    [api.GetDestinationDonations.key, org.ein],
    () => api.GetDestinationDonations.execute(org.id),
    { enabled: showPills },
  );

  const grantCount = grantsResult.data?.data.length;
  const donationCount = donationsResult.data?.data.length;

  return <OrgCard org={org} grantCount={grantCount} donationCount={donationCount} showPills={showPills} {...props} />;
};
