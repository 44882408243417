import Image from 'next/future/image';
import { useMedia } from 'react-use';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { config } from '@endaoment-frontend/config';
import { ArrowIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import bestFriendsDT from '../../../images/screenshots/bestFriendsDT.png';
import carbonFundPageDT from '../../../images/screenshots/carbonFundPageDT.png';
import city3ProfilePageDT from '../../../images/screenshots/city3ProfilePageDT.png';
import discoverPH from '../../../images/screenshots/discoverPH.png';
import myFundsDT from '../../../images/screenshots/myFundsDT.png';
import orgSearchDT from '../../../images/screenshots/orgSearchDT.png';
import ppfaPH from '../../../images/screenshots/ppfaPH.png';
import ukrainePH from '../../../images/screenshots/ukrainePH.png';

import 'swiper/css';
import 'swiper/css/pagination';
import styles from './EndaomentInterfaceSection.module.scss';

const desktopScreenshots = [orgSearchDT, bestFriendsDT, myFundsDT, carbonFundPageDT, city3ProfilePageDT];
const mobileScreenshots = [ukrainePH, ppfaPH, discoverPH];

export const EndaomentInterfaceSection = () => {
  const isMobile = useMedia('(max-width: 71rem)', true);

  return (
    <section className={styles['endaoment-interface-section-container']}>
      <div className={styles['endaoment-interface-section']}>
        <div className={styles['endaoment-interface-text']}>
          <div>
            <LogoIcon color="#53acde" width="100%" />
          </div>
          <h3>Endaoment Interface</h3>
          <hr />
          <p>
            Our easy-to-use application makes donating crypto and finding nonprofits fast and enjoyable. Simply connect
            your existing wallet to seamlessly donate, manage and grant your philanthropic capital.
          </p>
          <Button as="a" href={config.clientBaseUrl}>
            Launch App
            <ArrowIcon width={20} height={12} />
          </Button>
        </div>

        {isMobile ? (
          <Swiper
            direction="horizontal"
            loop
            pagination
            modules={[Pagination]}
            className={styles['endaoment-interface-mobile-carousel']}
          >
            {mobileScreenshots.map((src, i) => (
              <SwiperSlide className={styles['screenshot']} key={i}>
                <Image src={src} alt={`Mobile Screenshot ${i}`} priority height="200" width="230" placeholder="blur" />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            <Swiper
              direction="vertical"
              slidesPerView={1.8}
              spaceBetween={60}
              loop
              modules={[Autoplay]}
              speed={20000}
              autoplay={{ delay: 0, disableOnInteraction: false }}
              className={styles['endaoment-interface-desktop-carousel']}
            >
              {mobileScreenshots.map((src, i) => (
                <SwiperSlide className={styles['screenshot']} key={i}>
                  <Image
                    src={src}
                    alt={`Mobile Screenshot ${i}`}
                    priority
                    height="200"
                    width="230"
                    placeholder="blur"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              direction="vertical"
              slidesPerView={2}
              spaceBetween={60}
              loop
              modules={[Autoplay]}
              speed={20000}
              autoplay={{ delay: 0, disableOnInteraction: false, reverseDirection: true }}
              className={styles['endaoment-interface-desktop-carousel']}
            >
              {desktopScreenshots.map((src, i) => (
                <SwiperSlide className={styles['screenshot']} key={i}>
                  <Image
                    src={src}
                    alt={`Desktop Screenshot ${i}`}
                    priority
                    height="100"
                    width="600"
                    placeholder="blur"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>
    </section>
  );
};
