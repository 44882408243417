import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import { config } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes/app';
import { CircleIcon } from '@endaoment-frontend/ui/icons';
import { Card, Pill } from '@endaoment-frontend/ui/shared';
import {
  getFormattedEIN,
  getFormattedMoney,
  getNTEEMajorCode,
  parseHtml,
  stringToColor,
} from '@endaoment-frontend/utils';

import styles from './OrgCard.module.scss';
import { OrgCardProps } from './OrgCard.types';

// @TODO: remove Link from OrgCard, let parent decide how to handle
export const OrgCard = ({
  org,
  donationCount = 0,
  grantCount = 0,
  className,
  showPills = false,
  grantAmount,
}: OrgCardProps) => {
  return (
    <Link href={config.clientBaseUrl + routes.org({ ein: org.ein })}>
      <a className={clsx(styles['org-card'], className)}>
        <Card>
          {/* @TODO: use org's banner when present */}
          <div className={styles['banner']}>
            <Image src={`/images/ntee/banner/${getNTEEMajorCode(org)}.svg`} layout="fill" />
          </div>

          <div className={styles['content']}>
            <div className={styles['avatar']}>
              <div className={styles['avatar-container']}>
                {org.logo ? (
                  <img src={org.logo} alt={`${org.name} logo`} loading="lazy" />
                ) : (
                  <CircleIcon
                    startColor={stringToColor(org.name)}
                    endColor={stringToColor(org.name.split('').reverse().join(''))}
                  />
                )}
              </div>
            </div>
            <div className={styles['header']}>
              <h4 className={styles['name']}>{org.name}</h4>
              {org.lifetimeContributions && org.lifetimeContributions !== '0' && (
                <div className={styles['total']}>
                  <h4 className={styles['total-amount']}>
                    {getFormattedMoney(Number.parseFloat(org.lifetimeContributions))}
                  </h4>
                  <span className={styles['total-label']}>Total Donated</span>
                </div>
              )}
            </div>
            <p className={styles['description']}>{parseHtml(org.description)}</p>
            {showPills && (
              <div className={styles['pills']}>
                {grantCount > 0 && (
                  <Pill className={clsx(styles['pill'], styles['pill--grants'])} outline>
                    {grantCount} Grant{grantCount > 1 && 's'}
                  </Pill>
                )}
                {donationCount > 0 && (
                  <Pill className={clsx(styles['pill'], styles['pill--donations'])} outline>
                    {donationCount} Donation{donationCount > 1 && 's'}
                  </Pill>
                )}
              </div>
            )}
            <div className={styles['footer']}>
              <span>
                {grantAmount ? (
                  <>
                    From fund: <em>{getFormattedMoney(Number.parseFloat(grantAmount))}</em>
                  </>
                ) : (
                  <>
                    EIN: <em>{getFormattedEIN(org.ein)}</em>
                  </>
                )}
              </span>
              <span>
                <em>
                  {org.orgCity}
                  {org.orgState && `, ${org.orgState}`}
                </em>
              </span>
            </div>
          </div>
        </Card>
      </a>
    </Link>
  );
};
